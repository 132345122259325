import axios from "axios";
import { baseURL } from "../Constant";

export const exportAuditCsv = async () => {
  try {
      const response = await axios.get(`${baseURL}export-audit-csv`, {
        responseType: "blob",
      });
       if (response.status === 200) {
         const blob = new Blob([response.data], { type: "text/csv" });
         const url = window.URL.createObjectURL(blob);
         const link = document.createElement("a");
         link.href = url;
         link.download = "tbl_audits.csv"; 
         link.click();
         window.URL.revokeObjectURL(url);
       } else {
         console.error("Failed to export CSV:", response);
       }
  } catch (err) {
    console.log("error while export audit csv");
  }
};


export const exportFreelancerlCsv = async () => {
  try {
    const response = await axios.get(`${baseURL}export-freelancertbl-csv`, {
      responseType: "blob",
    });
    if (response.status === 200) {
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "freelancertbl.csv";
      link.click();
      window.URL.revokeObjectURL(url);
    } else {
      console.error("Failed to export CSV:", response);
    }
  } catch (err) {
    console.log("error while export audit csv");
  }
};
